import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Container from "../components/container";

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout pageTitle={`${post.frontmatter.title}`}>
      <Container verticalPadding={false} extraClasses={"pv2 pv4-ns"} maxWidth={1200}>
        <h1 className="tc">{post.frontmatter.title}</h1>
        <div className="measure-wide center" dangerouslySetInnerHTML={{ __html: post.html }}/>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query BasicQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
